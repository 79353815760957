
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
<StrictMode>

    <App />
</StrictMode>
,
  document.getElementById("root")
);