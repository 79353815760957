import logo from './logo.svg';

import Background from './resources/bg.jpg';
import {Paper, Card} from "@material-ui/core"
import { lightGreen } from '@material-ui/core/colors';
function Page() {
  console.log(Background)
  return (
    <div style={{height:9000}}>
      <div style={{backgroundColor:"#181818",padding:20, opacity:0.9, color:"white"}}>
        <h1>Nagy Hedvig festőmővész</h1>
        </div>
      <Card 
       style={{width:"50%", position:"absolute",  left: "100px", padding:10,opacity:0.8,
       top: "150px"}}
       
       >


        <h1>
          Kellemes ünnepeket!  :)
        </h1>
      </Card>

    </div>
  
  )
}

export default Page;
