import logo from './logo.svg';
import './App.css';
import Page from './page.js';
import ReactDOM from 'react-dom';

function App() {
  return (
    <div className="App">
    <Page></Page>
    
     </div>

  );
}

export default App;
